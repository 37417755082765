var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isFacebookVideo
        ? _c("iframe", {
            staticStyle: { border: "none", overflow: "hidden" },
            attrs: {
              src: _vm.facebookEmbedUrl,
              scrolling: "no",
              frameborder: "0",
              allowTransparency: "true",
              allowFullScreen: "true",
            },
          })
        : _c("video-embed", { attrs: { src: _vm.src } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }