<template>
  <div>
    <iframe
        v-if="isFacebookVideo"
        :src="facebookEmbedUrl"
        style="border:none;overflow:hidden"
        scrolling="no"
        frameborder="0"
        allowTransparency="true"
        allowFullScreen="true"
    ></iframe>
    <video-embed  v-else :src="src"></video-embed>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    isFacebookVideo() {
      return this.src.includes("facebook.com");
    },
    facebookEmbedUrl() {
      return `https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
          this.src
      )}`;
    },
  },
};
</script>
