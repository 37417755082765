<template>
  <div>
    <div v-if="selectedImage">
      <div class="imageWrapper position-relative">
        <template v-if="selectedImage.isNSFW">
          <img :src="selectedImage.full" v-img="selectedImage.full" />
        </template>
        <template v-else>
          <img :src="selectedImage.thumb_path" v-img="selectedImage.thumb_path" />
        </template>
        <b-icon class="zoom-icon" v-if="!isFullscreen" icon="arrows-angle-expand" @click="toggleFullscreen"></b-icon>
        <b-icon class="zoom-icon" v-else icon="arrows-angle-contract" @click="toggleFullscreen"></b-icon>
      </div>
    </div>
    <div v-else>
      Loading...
    </div>
    <v-container>
      <v-row justify="space-between" class="d-flex align-items-center">
        <v-col class="text-start" cols="6" sm="12" md="6">
          <small class="text-muted me-2">Publié {{ selectedImage.createdAt }}</small>
          <template v-if="selectedImage.gallery">
            <small class="text-muted me-2">Galerie : {{ selectedImage.gallery.name }}</small>
          </template>
          <template v-if="selectedImage.copyright">
            <small class="text-muted">Crédits photo : ©{{ selectedImage.copyright }}</small>
          </template>
        </v-col>
        <v-col class="text-end" cols="6" sm="12" md="6">
          <share-it :text="selectedImage.title" :targets="['facebook', 'twitter', 'linkedin']" dense/>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col class="text-start" v-html="selectedImage.description">
        </v-col>
      </v-row>
    </v-container>

    <template v-if="selectedImage.allowComments">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6" class="text-start mb-md-4">
            <div class="mb-4">
              <h4 class="mb-0">Ajouter un commentaire</h4>
              <p class="text-muted">Les commentaires sont soumis à une vérification avant d'être publiés.</p>
            </div>
            <div>
              <FormulateForm
                  v-model="formDatas"
                  :keep-model-data="true"
                  @submit="submitHandler"
                  #default="{ isLoading }"
              >

                <FormulateInput
                    type="hidden"
                    name="imageId"
                    :value="selectedImage.id"
                />

                <div class="double-wide">
                  <FormulateInput
                      type="text"
                      name="name"
                      label="Nom"
                      validation="required"
                      :validation-messages="{ required: 'Merci de renseigner ce champs' }"
                  />
                  <FormulateInput
                      type="text"
                      name="city"
                      label="Ville"
                      validation="required"
                      :validation-messages="{ required: 'Merci de renseigner ce champs' }"

                  />
                </div>
                <div class="double-wide">
                  <FormulateInput
                      type="text"
                      name="email"
                      label="Adresse e-mail"
                      validation="required|email"
                      :validation-messages="{ required: 'Merci de renseigner ce champs',email: 'Merci de renseigner une adresse e-mail valide' }"
                  />
                  <FormulateInput
                      type="url"
                      name="website"
                      label="Site web"
                  />
                </div>
                <FormulateInput
                    type="textarea"
                    name="comment"
                    label="Message"
                    validation="required"
                    :validation-messages="{ required: 'Merci de renseigner ce champs' }"
                />

                <FormulateInput
                    class=""
                    type="submit"
                    :disabled="isLoading"
                    :label="isLoading ? 'Loading...' : 'Envoyer'"
                />

              </FormulateForm>


            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="text-start">
            <h4 class="mb-4">Commentaires ({{ selectedImage.countComments }})</h4>
            <template v-if="selectedImage.countComments > 0">
              <ul class="list-unstyled">
                <li v-for="comment in comments" :key="comment.id">
                  <b-media class="d-flex mb-2">
                    <template #aside>
                      <template v-if="comment.website">
                        <a :href="comment.website" target="_blank">
                          <vue-letter-avatar :name='comment.name' size='40' :rounded=true />
                        </a>
                      </template>
                      <template v-else>
                        <vue-letter-avatar :name='comment.name' size='40' :rounded=true />
                      </template>
                    </template>
                    <div class="d-flex justify-content-between">
                      <template v-if="comment.website">
                        <a :href="comment.website" target="_blank">
                          <h6 class="mt-0 text-primary">{{ comment.name }}</h6>
                        </a>
                      </template>
                      <template v-else>
                        <h6 class="mt-0 text-dark">{{ comment.name }}</h6>
                      </template>
                      <small class="text-muted"> {{ comment.city }} - {{ comment.createdAt }}</small>
                    </div>
                    <p class="">
                      {{ comment.text }}
                    </p>
                  </b-media>
                </li>
              </ul>
            </template>
            <template v-else>
              <b-alert show variant="warning">Aucun commentaire pour l'instant.</b-alert>
            </template>
          </v-col>

        </v-row>
      </v-container>
    </template>
  </div>
</template>


<script>
const routes = require("../../../public/js/fos_js_routes.json");
import Routing from "../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js";

export default {
  props: {
    selectedImage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formDatas: {},
      comments: [],
      isFullscreen: false,
    };
  },
  mounted: function () {
    this.locale_request = $('html').attr('lang');
    $.ajax({
      url: Routing.generate("get_dynamic_comment_image", {imageId: this.selectedImage.id}),
      type: 'GET',
      success: (response) => {
        this.comments = response.comments;
      },
      error: (error) => {
        console.error(error);
      }
    });
    this.imagesId = ['182521', '182519'];
  },
  methods: {
    toggleFullscreen() {
      const element = document.documentElement;
      const isInFullScreen = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;

      if (!isInFullScreen) {
        // Activer le mode plein écran
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        }
        this.isFullscreen = true; // Mettre à jour la valeur de isFullscreen
      } else {
        // Désactiver le mode plein écran
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        this.isFullscreen = false; // Mettre à jour la valeur de isFullscreen
      }
    },
    checkInput() {
      // Vérifie si le champ du commentaire est vide ou non
      // pour activer ou désactiver le bouton "Envoyer"
      if (this.commentText.trim() !== '') {
        this.$refs.submitBtn.removeAttribute('disabled');
      } else {
        this.$refs.submitBtn.setAttribute('disabled', 'disabled');
      }
    },
    async submitHandler(data) {

      Swal.fire({
        title: "Veuillez patienter...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      await $.ajax({
        url: Routing.generate("send_dynamic_comment_image", {datas: this.formDatas}),
        type: "POST",
        data: this.formDatas,
        success: function (response) {

          Swal.fire({
            title: 'Commentaire envoyé !',
            text: 'Votre commentaire sera publié une fois qu\'il aura été validé.',
            icon: 'success',
            confirmButtonText: 'Fermer'
          });
        },
        error: function (jqXHR, textStatus, errorThrown) {
          Swal.fire({
            title: 'Erreur',
            text: 'Une erreur s\'est produite lors de l\'envoi du commentaire. Veuillez réessayer ultérieurement.',
            icon: 'error',
            confirmButtonText: 'Fermer'
          });
        },
      });
    },
  }
}
;
</script>


<style lang="css" scoped src="../components/modal.css"></style>
